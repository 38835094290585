import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Tab, Tabs } from '@mui/material';
import { TaskBarTabPanel } from '../task_bar/TaskBarTabs';
import { PredictSettings } from './PredictSettings';
import { TaskBarComponent, TaskBarGroup, TaskBarHeader } from '../task_bar/TaskBar';
import { ScenarioList } from './ScenarioList';

export function TravelTimePanel() {
  return (
    <TaskBarGroup>
      <TaskBarComponent left>
        <p
          style={{
            paddingLeft: '1em',
            paddingRight: '1em',
          }}
        >
          <b>Please select a route to see predicted travel times: </b>
          <br />
          Select segments while holding Ctrl to build a route.
          You can use Ctrl-Z to undo a route addition
        </p>
      </TaskBarComponent>
    </TaskBarGroup>
  );
}
