import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectBinSize,
  selectProject,
  selectRouteSelection,
  selectSegmentId,
  selectTypicalMonth
} from '../../state/workflowSlice';
import { LayerContext } from '../../state/LayerContext';
import {
  useGetLayerQuery,
  useGetPlanningRouteVolumesQuery,
  useGetPlanningVolumesQuery
} from '../../state/apiSlice';
import { getRouteForSparseSegments } from '../map/mapUtils';

export function usePlanningVolumesDataProvider() {
  const project_slug = useSelector(selectProject);
  const typicalMonth = useSelector(selectTypicalMonth);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const binSize = useSelector(selectBinSize);
  const { layer } = useContext(LayerContext);

  // typicalData from REST api
  let { currentData: planningVolumeData } = useGetPlanningVolumesQuery(
    { project_slug, typicalMonth, segmentId, binSize },
    { skip: !(typicalMonth && segmentId) || (typicalMonth && routeSelection) }
  ) as any;

  // Get data for Route volumes
  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });
  let routeSegments;

  if (routeSelection && layerData?.routing) {
    routeSegments = getRouteForSparseSegments(layerData.routing, routeSelection);
  }

  const { currentData: planningVolumeRouteData } = useGetPlanningRouteVolumesQuery(
    { project_slug, typicalMonth, routeIds: routeSegments, binSize },
    { skip: !(typicalMonth && routeSelection) }
  ) as any;

  if (planningVolumeRouteData) {
    planningVolumeData = planningVolumeRouteData;
  }

  return planningVolumeData;
}
