import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTimelineMetric, selectTimelineMetric, selectCurrentProjectInfo } from 'state/workflowSlice';

import styles from 'features/task_bar/Taskbar.module.css';
import { ToggleGroup } from 'features/common/ToggleGroup';
import { OverflowLabel } from 'features/task_bar/OverflowLabel';
import {
  TL_METRIC_OPTIONS_WITH_TYPICAL,
  TL_METRIC_TYP_REL_SPEED_VOL, TL_METRIC_TYP_REL_VOL,
  TL_METRIC_TYP_VHD,
  TL_METRIC_FF_REL_SPEED_VOL
} from './metricsOptions';
import { TypicalMonthPicker } from '../task_bar/TypicalMonthPicker';

export function PredictMetricsOption() {
  const userProject = useSelector(selectCurrentProjectInfo);
  const timelineMetric = useSelector(selectTimelineMetric);
  const dispatch = useDispatch();

  const availableMetrics = {
    [TL_METRIC_TYP_REL_SPEED_VOL]: 'Atypical Speeds',
    [TL_METRIC_FF_REL_SPEED_VOL]: 'Speeds',
    [TL_METRIC_TYP_REL_VOL]: 'Atypical Volumes',
    [TL_METRIC_TYP_VHD]: 'Atypical Delay'
  };
  const safeDefault = TL_METRIC_TYP_REL_SPEED_VOL;

  const tooltips = {
    [TL_METRIC_TYP_REL_VOL]: 'Observed vehicle activity compared to typical',
    [TL_METRIC_FF_REL_SPEED_VOL]: 'Speed compared to free-flow, widths indicate relative volume',
    [TL_METRIC_TYP_REL_SPEED_VOL]: 'Speed compared to typical, widths indicate relative volume',
    [TL_METRIC_TYP_VHD]: 'Vehicle hours of delay compared to typical'
  };

  const setMetric = (value) => {
    console.log(`TimelineMetricsOption handleChange newTimelineMetric: ${value}`);
    dispatch(setTimelineMetric(value));
  };

  const handleMetricToggleClick = (event) => {
    const newMetricToggleValue = event.target.value;
    setMetric(newMetricToggleValue);
  };

  useEffect(() => {
    if (availableMetrics[timelineMetric] === undefined) {
      dispatch(setTimelineMetric(safeDefault));
    }
  }, [dispatch, timelineMetric, userProject]);

  const mainMetricSelector = (
    <div>
      <OverflowLabel id="timeline-metric-picker-label">
        Metric:
        <ToggleGroup
          value={timelineMetric}
          values={Object.keys(availableMetrics)}
          tooltips={tooltips}
          label_map={availableMetrics}
          handleClick={handleMetricToggleClick}
        />
      </OverflowLabel>
    </div>
  );

  const typicalMonthSelector = (
    <TypicalMonthPicker />
  );

  return (
    <div className={styles.task_bar_component}>
      {mainMetricSelector}
      {typicalMonthSelector}
    </div>
  );
}
