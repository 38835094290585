import dayjs from 'dayjs';
import { posMod } from '../common/utils';

export function ts_to_minutes(ts:string, targetDate?: string):number {
  const t = dayjs(ts);
  if (targetDate) {
    const diff = t.diff(dayjs(targetDate), 'minute');
    return diff;
  } else {
    const m = (t.hour() * 60) + t.minute();
    return m;
  }
  // const offset = (60 * t.hour()) + t.minute();
}

export function min_string_to_minutes(ts:string):number {
  const t = dayjs(ts, 'HH:mm');
  const m = (t.hour() * 60) + t.minute();
  return m;
}

export function minutes_to_slots(mins: number, binSize: number): number {
  return Math.floor((mins + (3 * 60)) / binSize);
}

export function slots_to_minutes(slot: number, binSize: number): number {
  return (slot * binSize) - (3 * 60);
}

export function ts_to_slots(ts: string, binSize: number, targetDate?: string): number {
  const mins = ts_to_minutes(ts, targetDate);
  return minutes_to_slots(mins, binSize);
}

export function formatTimeOfDay(value: number): string {
  const hours = posMod(Math.floor(value / 60), 24);
  const minutes = posMod(value, 60);

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
}
