import React, { PropsWithChildren } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import styles from './Taskbar.module.css';
import { SplitOverflowLabel } from './OverflowLabel';
import { TaskBarComponent } from './TaskBar';

export function TaskBarSelect(
  { label, value, onChange, values }:
    { label: React.ReactNode, value: string, onChange: (event) => void, values: Array<{value: string, label: string}> }
) {
  return (
    <TaskBarComponent>
      <SplitOverflowLabel label={label}>
        <Select
          className={styles.task_bar_select_wide}
          value={value}
          onChange={onChange}
          size="small"
        >
          {values.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </SplitOverflowLabel>
    </TaskBarComponent>
  );
}
