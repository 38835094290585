import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TaskBarComponent } from 'features/task_bar/TaskBar';
import { selectCurrentScenarioId, selectProject } from '../../state/workflowSlice';
import {
  SCENARIO_DONE, SCENARIO_ERROR,
  selectScenarioStatus,
  setScenarioStatus,
  useGetScenarioStatusQuery
} from '../../state/predictSlices';

export function useScenarioStatus() {
  const project_slug = useSelector(selectProject);
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const refScenarioData = useRef(undefined);
  const response = useGetScenarioStatusQuery(
    { project_slug, uuid: currentScenarioId },
    {
      skip: !currentScenarioId
        || (
          [SCENARIO_DONE, SCENARIO_ERROR].includes(refScenarioData.current?.scenario?.status)
          && refScenarioData.current?.scenario?.uuid === currentScenarioId
        ),
      pollingInterval: 3 * 1000
    }
  );

  refScenarioData.current = response.currentData || refScenarioData.current;

  return response;
}

export function PredictScenarioStatusProvider() {
  const dispatch = useDispatch();
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const scenarioStatus = useSelector(selectScenarioStatus);
  const refScenarioData = useRef(undefined);

  const { currentData: scenarioData } = useScenarioStatus();

  refScenarioData.current = scenarioData || refScenarioData.current;

  useEffect(() => {
    dispatch(setScenarioStatus(refScenarioData.current?.scenario?.status));
    console.log(scenarioData, refScenarioData.current, scenarioStatus);
  }, [refScenarioData.current]);

  useEffect(() => {
    if (!currentScenarioId) {
      dispatch(setScenarioStatus(undefined));
    }
    refScenarioData.current = undefined;
  }, [currentScenarioId]);

  return (
    null
  );
}
