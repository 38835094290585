import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Add, Delete } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from '@mui/material';

import { TaskBarComponent, TaskBarGroup, TaskBarHeader } from 'features/task_bar/TaskBar';
import { OverflowLabel, SplitOverflowLabel } from 'features/task_bar/OverflowLabel';
import { PlanningMonthPicker } from '../task_bar/PlanningMonthPicker';
import styles from '../task_bar/Taskbar.module.css';
import { TaskBarSelect } from '../task_bar/TaskBarSelect';
import {
  selectProject,
  selectSegmentId,
  selectTypicalMonth,
  selectCurrentScenarioId,
  setCurrentScenarioId,
  setTypicalMonth, setSelectedSegmentId
} from '../../state/workflowSlice';
import {
  SCENARIO_DONE,
  SCENARIO_ERROR, SCENARIO_PENDING, selectScenarioDayType, selectScenarioEnd,
  selectScenarioSegments,
  selectScenarioStart,
  selectScenarioStatus, setScenarioDayType,
  setScenarioEnd,
  setScenarioSegments, setScenarioStart,
  useCreateScenarioMutation,
  useGetScenarioStatusQuery
} from '../../state/predictSlices';
import { stl_background_color, taskbar_color } from '../../theme/cemTheme';

function dropFromArray(array_in, item) {
  const array = [...array_in];
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function PredictSettingsData() {
  const project_slug = useSelector(selectProject);
  const dispatch = useDispatch();
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const { currentData: scenarioData } = useGetScenarioStatusQuery(
    { project_slug, uuid: currentScenarioId },
    {
      skip: !currentScenarioId
    }
  );

  useEffect(() => {
    if (scenarioData) {
      dispatch(setScenarioDayType(scenarioData.scenario.day_type));
      dispatch(setScenarioStart(scenarioData.scenario.closure_start.slice(0, -3)));
      dispatch(setScenarioEnd(scenarioData.scenario.closure_end.slice(0, -3)));
      dispatch(setTypicalMonth(scenarioData.scenario.year_month));
      dispatch(setSelectedSegmentId(scenarioData.scenario.segments[0]));
      dispatch(setScenarioSegments(scenarioData.scenario.segments));
    } else if (currentScenarioId === undefined) {
      dispatch(setSelectedSegmentId(undefined));
      dispatch(setScenarioSegments([]));
    }
  }, [scenarioData]);

  return (null);
}

export function PredictSettings() {
  const dayType = useSelector(selectScenarioDayType);
  const closureStart = useSelector(selectScenarioStart);
  const closureEnd = useSelector(selectScenarioEnd);
  const scenarioSegments = useSelector(selectScenarioSegments);
  const selectedSegmentId = useSelector(selectSegmentId);
  const scenarioStatus = useSelector(selectScenarioStatus);
  const project_slug = useSelector(selectProject);
  const typicalMonth = useSelector(selectTypicalMonth);
  const [createScenario, scenarioResult] = useCreateScenarioMutation();
  const { status, data: mutateScenarioData } = scenarioResult;
  const dispatch = useDispatch();
  const currentScenarioId = useSelector(selectCurrentScenarioId);

  const calculateClick = async (event) => {
    console.log(dayType, closureStart, closureEnd, selectedSegmentId);
    await createScenario({
      scenario_settings: {
        day_type: dayType,
        closure_start: closureStart,
        closure_end: closureEnd,
        segments: scenarioSegments,
        project_slug,
        year_month: typicalMonth
      },
      project_slug
    }).unwrap()
      .then(
        (res) => {
          console.log(res);
          dispatch(setCurrentScenarioId(res.scenario.uuid));
        }
      );
  };

  let is_loading = false;
  let button_copy = 'Calculate scenario';
  if (status === 'pending' || (!scenarioStatus && currentScenarioId)) {
    button_copy = 'Loading...';
    is_loading = true;
  } else if (currentScenarioId && scenarioStatus !== SCENARIO_DONE) {
    if (scenarioStatus === SCENARIO_PENDING) {
      button_copy = 'Processing scenario';
    } else {
      button_copy = `Calculating scenario (${capitalize(scenarioStatus)})`;
    }
    is_loading = true;
  }

  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const t = `${i.toFixed(0).padStart(2, '0')}:${(j * 15).toFixed(0).padStart(2, '0')}`;
      times.push({ value: t, label: t });
    }
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <TaskBarGroup>
      <TaskBarComponent>
        <Button
          onClick={() => { dispatch(setCurrentScenarioId(undefined)); }}
        >
          Clear scenario from map
        </Button>
      </TaskBarComponent>
      <PlanningMonthPicker />
      <TaskBarSelect
        label="Day Type:"
        value={dayType}
        onChange={(event) => { dispatch(setScenarioDayType(event.target.value)); }}
        values={[
          { value: 'Weekday', label: 'Weekday' },
          { value: 'Saturday', label: 'Saturday' },
          { value: 'Sunday', label: 'Sunday' },
        ]}
      />
      <TaskBarSelect
        label="Closure start:"
        value={closureStart || ''}
        onChange={(event) => { dispatch(setScenarioStart(event.target.value)); }}
        values={times}
      />
      <TaskBarSelect
        label="Closure end:"
        value={closureEnd || ''}
        onChange={(event) => { dispatch(setScenarioEnd(event.target.value)); }}
        values={times}
      />
      <TaskBarComponent left>
        Segments:
        <List dense className={styles.task_bar_scrolling_list}>
          {
            scenarioSegments && scenarioSegments.map((value, index) => (
              <ListItem
                key={value}
                secondaryAction={(
                  <IconButton
                    onClick={
                    (event) => dispatch(
                      setScenarioSegments(dropFromArray(scenarioSegments, value))
                    )
                    }
                  >
                    <Delete />
                  </IconButton>
                )}
                onClick={(event) => {}}
              >
                {value}
              </ListItem>
            ))
          }
        </List>
        <Button
          onClick={(event) => {
            if (selectedSegmentId && !scenarioSegments.includes(selectedSegmentId)) {
              dispatch(setScenarioSegments([...scenarioSegments, selectedSegmentId]));
            }
          }}
          startIcon={<Add />}
          disabled={!selectedSegmentId}
        >
          Add current selected segment
        </Button>
      </TaskBarComponent>
      <Button
        disabled={!(dayType && closureStart && closureEnd && (scenarioSegments.length > 0))}
        onClick={calculateClick}
        variant="contained"
        startIcon={is_loading && (
          <CircularProgress style={{ width: '1em', height: '1em', color: taskbar_color }} />
        )}
      >
        {button_copy}
      </Button>
      { is_loading
        && <i>ETA ~60 seconds</i> }
    </TaskBarGroup>
  );
}
