import React, { useEffect, useState, useMemo, useContext } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

export function TaskBarTabPanel({ children, value, index }: {children: React.ReactNode, value: string, index:string}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
}
