import React from 'react';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { SegmentInfo } from 'features/task_bar/SegmentInfo';

import styles from 'features/task_bar/Taskbar.module.css';
import { selectMaximize } from '../../state/workflowSlice';
import { MAXIMIZE_DATA } from '../../appConstants';
import { BookmarkToolbar } from '../bookmarks/BookmarkToolbar';

export function TaskBarHeader({ children } : PropsWithChildren) {
  return (
    <h2 className={styles.task_bar_header}>{children}</h2>
  );
}

export function TaskBarGroup({ children } : PropsWithChildren) {
  return (
    <div className={styles.task_bar_group}>
      {children}
    </div>
  );
}

export function TaskBarComponent(
  { children, centered = false, left = false } : { centered?: boolean, left?: boolean } & PropsWithChildren
) {
  let className = styles.task_bar_component;
  if (centered) {
    className = styles.task_bar_component_centered;
  } else if (left) {
    className = styles.task_bar_component_left;
  }
  return (
    <div className={className}>
      {children}
    </div>
  );
}

export function TaskBarSkeleton(
  { upper, middle, lower }: { upper: React.ReactNode, middle: React.ReactNode, lower: React.ReactNode }
) {
  const maximize = useSelector(selectMaximize);
  return (
    <div className={styles.task_bar + (maximize === MAXIMIZE_DATA ? ` ${styles.task_bar_hidden}` : '')}>
      {upper}
      <div className={styles.task_bar_section_filler} />
      {middle}
      <div className={styles.task_bar_graph_controls}>
        {lower}
      </div>
    </div>
  );
}

export function ChartSettingsWrapper({ children, extras } : { children: React.ReactNode, extras?: React.ReactNode }) {
  return (
    <>
      <TaskBarHeader>Chart Settings</TaskBarHeader>
      <div className={styles.task_bar_section_body}>
        {children}
      </div>
      <div className={styles.task_bar_section_filler} />
      {extras}
    </>
  );
}

export function TaskBar({ children: chartSettings, extras, segmentInfo = true, segmentInfoExtras = undefined }:
                          {
                            children: React.ReactNode;
                            extras?: React.ReactNode,
                            segmentInfo?: boolean,
                            segmentInfoExtras?: Array<React.ReactNode>
                          }) {
  return (
    <TaskBarSkeleton
      upper={
      (
        <>
          <BookmarkToolbar />
          {
            segmentInfo
            && (
              <TaskBarGroup>
                <TaskBarHeader>Segment information</TaskBarHeader>
                <div className={styles.task_bar_section_body}>
                  <SegmentInfo
                    extras={segmentInfoExtras}
                  />
                </div>
              </TaskBarGroup>
            )
          }
        </>
      )
    }
      middle={(null)}
      lower={(
        <ChartSettingsWrapper extras={extras}>
          {chartSettings}
        </ChartSettingsWrapper>
      )}
    />
  );
}
