import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import mapboxgl from 'mapbox-gl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import styles from 'features/map/Map.module.css';

import {
  selectSegmentId,
  setSelectedSegmentId,
  setMapCenter,
  setMapZoom,
  selectProject,
  setRouteSelection,
  selectRouteSelection,
  selectCurrentProjectInfo,
  selectMapCenter,
  selectTargetDate,
  selectTimeOfDay,
  selectTimelineMetric,
  selectComparison,
  selectMapZoom,
  selectMapStyle, selectShortestAvailableBin
} from 'state/workflowSlice';
import {
  useGetLayerQuery
} from 'state/apiSlice';

import { LayerContext } from 'state/LayerContext';

import { optionallyCall } from 'appUtils';
import {
  ToggleLayerControl,
  toggleLayerVisibility,
  enableChartButton,
  ensureRtlLoaded,
  findNearestSegmentLatLng, getLayerToggleVisibility, launderLatLng, latLngToJsonPoint
} from 'features/map/mapUtils';
import {
  TL_METRIC_OPTIONS,
  counts_absolute_size_expr, metricExprDeepSub, isRealtimeMetric, isVNRTMetric
} from 'features/workflow_timeline/metricsOptions';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'features/map/Map.css';
import { kHalfDirectionArrowNameLHD, kHalfDirectionArrowNameRHD } from 'features/map/MapIconProvider';
import {
  kSegmentLayerId,
  initMap,
  mapShowingSelectedSegment,
  mapShowingSegments,
  mapPopSelectedSegment,
  getInitialCenter,
  thick_line_width_expression,
  kSegmentSymbolsLayerId,
  kSegmentLayerArrowDefinition,
  icon_size_expression,
  redrawBaseSegments,
  kMainVisLineOpacityExpression,
  kSegmentHoverId,
  kSegmentLayerSourceLayerId,
  showBookmarks,
  safeRemoveBookmarks, updateMapLocation, mapHasLayer, kSegmentSourceId, kMainVisLineOpacity
} from 'features/map/mapCommon';
import { transparent_null_grey } from 'theme/cemTheme';

import { TimelineLegend } from './TimelineLegend';
import { formatSpeed, KM_TO_MILES } from '../common/utils';
import { mapboxApiKey } from '../../appConstants';
import { selectDisplayedBookmark, selectInLocationEditMode, setDisplayedBookmark } from '../../state/bookmarkSlices';
import { FloatingDiv } from '../spinner/Spinner';
import { getMinuteTicker, useRealtimeData } from './RealtimeDataProvider';
import { setRepresentedSlots, selectRepresentedSlots, selectVnrtData } from '../../state/realtimeSlices';
import { useDayInfo } from '../task_bar/DatePicker';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { minutes_to_slots, formatTimeOfDay } from './slotsUtils';
import { MapSettings } from '../map/MapSettings';
import { selectUserState } from '../../state/userSlice';

dayjs.extend(utc);
mapboxgl.accessToken = mapboxApiKey;

const kColorUpdateMillis = 200;
const kMouseHoverTimeoutMillis = 300;
const kTicksShowHideId = 'ticks-showhide';

let lastColorUpdate;
let lastColorUpdateRef;

function toPrecisionUp(number, precision) {
  precision -= Math.floor(number).toString().length;
  const order = 10 ** precision;
  number *= order;
  return (Math.ceil(number) / order);
}

const timelineMinZoom = 12;

export function TimelineMap() {
  // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

  const dispatch = useDispatch();

  // useRef DOM refs and for variables accessed from map callbacks
  const refMap = useRef<mapboxgl.Map>(null); // mapboxgl.Map object
  const refPopup = useRef<mapboxgl.Popup>(null); // mapboxgl.Map object
  const refMapContainer = useRef(null); // DOM node reference
  const refRouting = useRef({});
  const refMouseoverTimeout = useRef(null);

  const hourlyMaxCount = useRef(1000);

  const [mapReady, setMapReady] = useState(false);
  const refStyleReady = useRef(false);
  const [zoomPrompt, setZoomPrompt] = useState(true);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { layer } = useContext(LayerContext);
  const selectedSegmentId = useSelector(selectSegmentId);
  const selectedRoute = useSelector(selectRouteSelection);
  const project = useSelector(selectProject);
  const currentMapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const targetDate = useSelector(selectTargetDate);
  const timeOfDay = useSelector(selectTimeOfDay);
  const userProject = useSelector(selectCurrentProjectInfo);
  const binSize = useSelector(selectShortestAvailableBin);
  const timelineMetric = useSelector(selectTimelineMetric);
  const refTimelineMetric = useRef(timelineMetric);
  const comparison = useSelector(selectComparison);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const slotsRepresented = useSelector(selectRepresentedSlots);
  const vnrtData = useSelector(selectVnrtData);
  const refVnrtData = useRef(vnrtData);
  const refExpectingSDE = useRef(undefined); // We use this to debounce the fact that many things are causing a source data event that shouldn't
  const refEnrichedTiles = useRef({});
  const refTargetDate = useRef(targetDate);
  const mapStyle = useSelector(selectMapStyle);
  const userInfo = useSelector(selectUserState);

  const timeOfDayOffset = minutes_to_slots(timeOfDay, binSize || 15);
  const timeOfDayOffsetRef = useRef({ offset: timeOfDayOffset, time: timeOfDay });
  timeOfDayOffsetRef.current = { offset: timeOfDayOffset, time: timeOfDay };
  const refInLocationEditMode = useRef(inLocationEditMode);

  // load segmentData for layer from REST api (or cache)
  const { currentData: layerData, } = useGetLayerQuery(layer, { skip: !layer });
  const { day_info } = useDayInfo();
  const refDayInfo = useRef(day_info);
  const t = getMinuteTicker();
  const { realtime: realtimeData } = useRealtimeData(t);
  const refRealtimeData = useRef(realtimeData);

  // useSegmentSelectionCrosswalk();

  refRouting.current = useMemo(() => layerData?.routing, [layerData]);

  ensureRtlLoaded();

  function getColorExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    const NULL_PLACEHOLDER = 1000000;
    if (!setting) {
      console.error(`Setting not found for metric ${metric}`);
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: userProject?.uses_metric
    };
    return [
      'interpolate',
      ['linear'],
      ['coalesce',
        setting.metric_expr(settingsArgs),
        NULL_PLACEHOLDER
      ],
      ...optionallyCall(setting.colors, settingsArgs),
      NULL_PLACEHOLDER,
      transparent_null_grey
    ];
  }

  function getShowExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (!setting) {
      console.error(`Setting not found for metric ${metric}`);
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: userProject?.uses_metric
    };
    if (!setting.shown) {
      return true;
    } else {
      const shown = metricExprDeepSub([...setting.shown], setting.metric_expr(settingsArgs));
      return shown;
    }
  }

  function getLineWidthExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) => counts_absolute_size_expr(timeOfDayOffset, Number(hourlyMaxCount.current), base);
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8, counts_expr(1),
        14, counts_expr(3),
        16, counts_expr(8),
        19, counts_expr(20)
      ];
    } else {
      return thick_line_width_expression;
    }
  }

  function getLineOffsetExpression() {
    let sign = -1;
    if (userProject?.right_hand_drive) {
      sign = 1;
    }
    return [
      'interpolate',
      ['linear'],
      ['zoom'],
      8, sign * 1,
      14, sign * 2,
      16, sign * 3,
      19, sign * 5
    ];
  }

  function getIconSizeExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) => counts_absolute_size_expr(timeOfDayOffset, Number(hourlyMaxCount.current), base);
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8, counts_expr(0.4),
        14, counts_expr(0.4),
        16, counts_expr(0.8),
        19, counts_expr(1)
      ];
    } else {
      return icon_size_expression;
    }
  }

  const segmentLayerDefinition = {
    id: kSegmentLayerId,
    type: 'line',
    source: kSegmentSourceId,
    'source-layer': kSegmentLayerSourceLayerId,
    layout: {},
    paint: {
      'line-color': getColorExpression(timelineMetric),
      'line-width': getLineWidthExpression(timelineMetric),
      'line-opacity': 0.7,
      'line-offset': getLineOffsetExpression(),
    },
  };

  const segmentArrowLayerDefinition = {
    ...kSegmentLayerArrowDefinition,
    paint: {
      'icon-color': getColorExpression(timelineMetric),
      'icon-opacity': kMainVisLineOpacityExpression(kMainVisLineOpacity),
    }
  };

  segmentArrowLayerDefinition.layout['icon-image'] = userProject?.right_hand_drive ? kHalfDirectionArrowNameRHD : kHalfDirectionArrowNameLHD;
  segmentArrowLayerDefinition.layout['icon-size'] = getIconSizeExpression(timelineMetric) as any;
  (segmentArrowLayerDefinition.layout as any).visibility = getLayerToggleVisibility(kTicksShowHideId) ? 'visible' : 'none';

  const hoverLayerDefinition = {
    ...segmentLayerDefinition,
    id: kSegmentHoverId,
    paint: {
      ...segmentLayerDefinition.paint,
      'line-color': '#000000',
      'line-opacity': 0
    }
  };

  function updateColorExpression() {
    lastColorUpdate = Date.now();
    refMap.current.setFilter(kSegmentLayerId, getShowExpression(timelineMetric));
    refMap.current.setPaintProperty(kSegmentLayerId, 'line-width', getLineWidthExpression(timelineMetric));
    refMap.current.setPaintProperty(kSegmentLayerId, 'line-color', getColorExpression(timelineMetric));
    if (getLayerToggleVisibility(kTicksShowHideId)) {
      refMap.current.setPaintProperty(kSegmentSymbolsLayerId, 'icon-color', getColorExpression(timelineMetric));
      const ice = getIconSizeExpression(timelineMetric);
      refMap.current.setLayoutProperty(kSegmentSymbolsLayerId, 'icon-size', getIconSizeExpression(timelineMetric));
    }
    refExpectingSDE.current = Date.now();
  }

  const handleSegmentClickCallback = useCallbackWithErrorHandling((event) => {
    // console.log(`handleSegmentClickCallback ${JSON.stringify(event.lngLat)} refSegments.current ${JSON.stringify(refSegments.current.length)}`);
    if (refInLocationEditMode.current) {
      dispatch(setDisplayedBookmark(launderLatLng(event.lngLat)));
    } else {
      if (mapShowingSelectedSegment(refMap.current)) mapPopSelectedSegment(refMap.current);
      dispatch(setRouteSelection(undefined));
      dispatch(setSelectedSegmentId(undefined));
    }
  });

  function showMouseTooltipForFeature(feature, lngLat) {
    refMap.current.getCanvas().style.cursor = 'pointer';
    const NA = 'No sample';
    if (refMouseoverTimeout.current) {
      clearTimeout(refMouseoverTimeout.current);
    }
    const { nearestSegmentPointLatLng } = findNearestSegmentLatLng([feature], lngLat);
    let description;

    function formatSpeeds(value) {
      if (!value || value.toString() === '-1') {
        return NA;
      }
      return formatSpeed(value * KM_TO_MILES, userProject?.uses_metric);
    }

    function formatCounts(value) {
      if (!value) {
        return NA;
      }
      return value.toString().split('.')[0];
    }

    function indexIntoProperty(featurex, prefix) {
      const prop_key = `${prefix}${timeOfDayOffsetRef.current.offset}`;
      const value = featurex.properties[prop_key] || featurex.state[prop_key];
      if (!value || value === '-1') {
        return undefined;
      } // else {
      //   // @ts-ignore: Use array destructuring
      //   // eslint-disable-next-line
      //   value = value.split('.')[0];
      // }
      return value.toString();
    }

    const time = formatTimeOfDay(timeOfDayOffsetRef.current.time);

    const counts = indexIntoProperty(feature, 'c');
    const speeds = indexIntoProperty(feature, 's');
    const typicalCounts = indexIntoProperty(feature, 'tc');
    const typicalSpeeds = indexIntoProperty(feature, 'ts');
    const rtSpeed = formatSpeeds(feature.state.rtspeed);
    const freeflowSpeed = formatSpeeds(feature.properties.freeflow_speed_kph || feature.properties.vnrt_freeflow_kph);
    if (isRealtimeMetric(refTimelineMetric.current)) {
      description = `
          <h1>${feature.properties.name}</h1>
          <table>
            <tr><td>Realtime speed</td><td>${rtSpeed}</td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(typicalSpeeds)}</td></tr>
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
          </table>`;
    } else if (isVNRTMetric(refTimelineMetric.current)) {
      description = `
          <h1>${feature.properties.name}</h1>
          <table>
            <tr><td><b>Speed at ${time}</b></td><td><b>${formatSpeeds(speeds)}</b></td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(typicalSpeeds)}</td></tr>
            ${rtSpeed !== NA ? `<tr><td>Realtime speed</td><td>${rtSpeed}</td></tr>` : ''}
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
          </table>`;
    } else {
      description = `
          <h1>${feature.properties.name}</h1>
          <table>
            <tr><td><b>Speed at ${time}</b></td><td><b>${formatSpeeds(speeds)}</b></td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(typicalSpeeds)}</td></tr>
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
            <tr><td><b>Trip count</b></td><td><b>${formatCounts(counts)}</b></td></tr>
            <tr><td>Typical count</td><td>${formatCounts(typicalCounts)}</td></tr>
          </table>`;
    }

    if (description && Number.isFinite(nearestSegmentPointLatLng?.lat)) {
      // Populate the popup and set its coordinates
      // based on the feature found.
      refPopup.current.setLngLat(nearestSegmentPointLatLng).setHTML(description).addTo(refMap.current);
    }
  }

  function hideMouseover() {
    refPopup.current.remove();
    refMouseoverTimeout.current = undefined;
  }

  const handleMouseLeaveEvent = useCallbackWithErrorHandling((event) => {
    if (!refMouseoverTimeout.current) {
      if (refPopup.current.isOpen()) {
        refMouseoverTimeout.current = { handle: setTimeout(hideMouseover, kMouseHoverTimeoutMillis), set: Date.now() };
      }
    } else if (Date.now() - refMouseoverTimeout.current.set > kMouseHoverTimeoutMillis * 2) {
      hideMouseover();
    }
  });

  const handleMouseMoveEvent = useCallbackWithErrorHandling((event: mapboxgl.MapMouseEvent) => {
    const { point } = event;
    const width = 8;
    const height = 8;
    if (!mapShowingSegments(refMap.current)) return;
    const features = refMap.current.queryRenderedFeatures(
      [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2]
      ],
      { layers: [kSegmentHoverId] }
    );
    if (features.length > 0) {
      const { nearestSegment } = findNearestSegmentLatLng(features as any, event.lngLat);
      showMouseTooltipForFeature(nearestSegment, event.lngLat);
    } else {
      handleMouseLeaveEvent(event);
    }
  });

  const handleMapMoveCallback = useCallbackWithErrorHandling(() => {
    const center = { lng: refMap.current.getCenter().lng.toFixed(4), lat: refMap.current.getCenter().lat.toFixed(4) };
    const newZoom = refMap.current.getZoom().toFixed(2);
    dispatch(setMapZoom(newZoom));
    dispatch(setMapCenter(center));
  });

  function handleTicksShowHideClickCallback() {
    toggleLayerVisibility(kTicksShowHideId, kSegmentSymbolsLayerId, refMap.current);
  }

  function handleZoomPromptUpdate() {
    if (refMap.current) {
      if (refMap.current.getZoom() < timelineMinZoom) {
        setZoomPrompt(true);
      } else {
        setZoomPrompt(false);
      }
    }
  }

  function updateFeaturesWithRt() {
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      if (refRealtimeData.current) {
        console.debug('Loading rt data into the map');
        const speed_key = 'rtspeed';
        const rt_keys = Object.keys(refRealtimeData.current.data);
        for (let i = 0; i < rt_keys.length; i++) {
          const id = rt_keys[i];
          refMap.current.setFeatureState({
            source: kSegmentSourceId,
            sourceLayer: kSegmentLayerSourceLayerId,
            id
          }, { [speed_key]: refRealtimeData.current.data[id] });
        }
        return true;
      }
    }
    return false;
  }

  function updateFeaturesWithVNRT() {
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      const l_vnrtData = refVnrtData.current;
      if (Object.keys(l_vnrtData).length > 0) {
        console.debug('Loading vnrt data into the map');
        const segs = {};
        const slots_keys = Object.keys(l_vnrtData);
        for (let i = 0; i < slots_keys.length; i++) {
          for (let j = 0; j < l_vnrtData[slots_keys[i]].length; j++) {
            const r = l_vnrtData[slots_keys[i]][j];
            if (!segs[r.s]) segs[r.s] = [];
            segs[r.s].push(r);
          }
        }
        // Segs contains a map of time and value objects
        const segs_keys = Object.keys(segs);
        for (let i = 0; i < segs_keys.length; i++) {
          const id = segs_keys[i];
          const new_state = {};
          for (let j = 0; j < segs[id].length; j++) {
            const data = segs[id][j];
            const speed_offset_key = `s${data.o.toFixed(0)}`;
            new_state[speed_offset_key] = data.v;
          }
          refMap.current.setFeatureState({
            source: kSegmentSourceId,
            sourceLayer: kSegmentLayerSourceLayerId,
            id
          }, new_state); // Need to convert time -> offset by dividing by length of bin, then format the speed key with it
        }
        refExpectingSDE.current = Date.now();
      }
      return true;
    }
    return false;
  }

  function updateSlotsRepresented() {
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      const renderedFeatures = refMap.current.querySourceFeatures(
        kSegmentSourceId,
        {
          sourceLayer: kSegmentLayerSourceLayerId
        }
      );
      const slots = {};
      let relevant_features = 0;
      for (let i = 0; i < renderedFeatures.length; i++) { // Probably can magically subselect only a handful of features? Might only ever get to around 1000 so not worth it
        const feature = renderedFeatures[i];
        if (feature.properties.date === refTargetDate.current) {
          relevant_features += 1;
          const props = feature.properties || {};
          const state = feature.state || {};
          const keys = [...Object.keys(props), ...Object.keys(state)];
          for (let j = 0; j < keys.length; j++) {
            const k = keys[j];
            if (k.startsWith('s')
              && (props[k] || state[k])) {
              slots[k] = (slots[k] || 0) + 1;
            }
          }
        }
      }
      const final_slots = {};
      const slots_keys = Object.keys(slots);
      for (let i = 0; i < slots_keys.length; i++) {
        const k = slots_keys[i];
        if (slots[k] > relevant_features / 2) { // Slot is represented if more than 1/2 of the features have it
          final_slots[k.replace('s', '')] = true;
        }
      }
      dispatch(setRepresentedSlots(final_slots));
      return true;
    }
    return false;
  }

  const handleSourceDataEvent = useCallbackWithErrorHandling((e: mapboxgl.MapSourceDataEvent) => {
    // Given we now rely on the segments being loaded via tiles to render
    // Selected and routes, we have to listen to new tiles being rendered
    // In case the selected segments or any of the route segments are suddenly in view
    if (e.sourceId === kSegmentSourceId) {
      const tileId = e.tile?.tileID?.canonical;
      if (tileId) {
        const textTileId = `${refTargetDate.current},${tileId.z},${tileId.x},${tileId.y}`;
        if (!refEnrichedTiles.current[textTileId] && !refDayInfo.current?.is_nrt) {
          // if (refExpectingSDE.current) console.log(refExpectingSDE.current);
          refExpectingSDE.current = undefined;
          const vnrt_enriched = updateFeaturesWithVNRT();
          const rt_enriched = updateFeaturesWithRt() || refDayInfo.current?.is_complete_vnrt;
          const slots_enriched = updateSlotsRepresented();
          const enriched = rt_enriched && slots_enriched && vnrt_enriched;
          if (enriched) {
            refEnrichedTiles.current[textTileId] = true;
            // console.log('Marking tile enriched', textTileId);
          } else {
            // console.log('Tile not sufficiently enriched', textTileId, vnrt_enriched, rt_enriched, slots_enriched);
          }
        } else {
          // console.log('Tile already enriched', textTileId);
        }
      }
    }
  });

  const handleMapLoadCallback = useCallbackWithErrorHandling(() => {
    // console.log('Map ready!');
    setMapReady(true);
    refStyleReady.current = true;
    updateFeaturesWithRt();
    updateFeaturesWithVNRT();
    updateSlotsRepresented();
  });

  const handleMapSettingsClickCallback = (e) => {
    setSettingsOpen(true);
  };

  useEffect(() => {
    if (refMap.current && (refMap.current as any).cemStyle !== mapStyle) {
      refMap.current.remove();
      refMap.current = undefined;
      setMapReady(false);
      // console.log('Wipe old map');
    }
    if (!refMap.current) {
      const { center, zoom } = getInitialCenter(userProject);
      if (center) {
        refStyleReady.current = false;
        setMapReady(false);
        // console.log('Set up map', userProject, mapStyle);
        initMap(
          refMap,
          refMapContainer,
          center,
          zoom,
          mapStyle,
          userInfo.has_hidden_features,
          handleMapLoadCallback,
          handleMapMoveCallback,
          handleSegmentClickCallback,
          handleMapSettingsClickCallback
        );
        lastColorUpdate = Date.now();
        refPopup.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
        refMap.current.on('mousemove', handleMouseMoveEvent);
        refMap.current.on('sourcedata', handleSourceDataEvent);
        refMap.current.addControl(new ToggleLayerControl(kTicksShowHideId, 'Show/hide ticks', false, handleTicksShowHideClickCallback));
        refMap.current.on('zoomend', handleZoomPromptUpdate);
        (refMap.current as any).cemStyle = mapStyle;
        handleZoomPromptUpdate();
      } else {
        console.log('Project defaults not yet loaded, skipping initialisation of map');
      }
    }
  }, [userProject, mapStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  // redrawSegments
  useEffect(
    () => {
      // console.log(`============= useeffect redrawSegments (mapReady: ${mapReady}`);
      // console.log('Map ready: ', mapReady);
      if (mapReady && refMap.current && refStyleReady.current) {
        (segmentArrowLayerDefinition.layout as any).visibility = getLayerToggleVisibility(kTicksShowHideId) ? 'visible' : 'none';
        redrawBaseSegments(
          mapReady,
          refMap.current,
          segmentLayerDefinition,
          `server/tiles/timeline/${project}/${targetDate}/${comparison}`,
          segmentArrowLayerDefinition,
          hoverLayerDefinition,
          timelineMinZoom,
          16
        );
      }
    },
    // we don't need dependency on selectedSegmentId even though we draw the selected statement
    // because (besides initial render) it only erasing/drawing it is handled by handleSegmentClickCallback
    [mapReady, targetDate, comparison, selectedSegmentId, selectedRoute] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (refMap.current) {
        const { center } = getInitialCenter(userProject);
        refMap.current.jumpTo({ center });
      }
    },
    [project]
  );

  useEffect(
    () => {
      if (mapShowingSegments(refMap.current)) {
        if (!lastColorUpdate || Date.now() - lastColorUpdate > kColorUpdateMillis) {
          clearTimeout(lastColorUpdateRef);
          updateColorExpression();
        } else {
          clearTimeout(lastColorUpdateRef);
          lastColorUpdateRef = setTimeout(updateColorExpression, kColorUpdateMillis);
        }
      }
    },
    [timeOfDayOffset, timelineMetric, targetDate]
  );

  useEffect(
    () => {
      updateMapLocation(refMap.current, mapZoom, currentMapCenter);
    },
    [currentMapCenter, mapZoom]
  );

  useEffect(() => {
    if (refTargetDate.current !== targetDate) {
      console.log('Changed dates, wiping represented slots and enriched tiles');
      dispatch(setRepresentedSlots({}));
      refEnrichedTiles.current = {};
    }
    refTargetDate.current = targetDate;
  }, [targetDate]);

  useEffect(
    () => {
      refRealtimeData.current = realtimeData;
      updateFeaturesWithRt();
    },
    [realtimeData, timelineMetric]
  );

  useEffect(
    () => {
      updateFeaturesWithVNRT();
    },
    [timelineMetric, refVnrtData.current]
  );

  useEffect(
    () => {
      refTimelineMetric.current = timelineMetric;
    },
    [timelineMetric]
  );

  useEffect(
    () => {
      refVnrtData.current = vnrtData;
    },
    [vnrtData]
  );

  useEffect(
    () => {
      refDayInfo.current = day_info;
    },
    [day_info]
  );

  useEffect(() => {
    refInLocationEditMode.current = inLocationEditMode;
    if (mapReady) {
      if (inLocationEditMode) {
        if (!displayedBookmark) {
          dispatch(setDisplayedBookmark({ ...refMap.current.getCenter() }));
        } else {
          const bookmarks = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: latLngToJsonPoint(displayedBookmark)
              }
            ]
          };
          showBookmarks(refMap.current, bookmarks);
        }
      } else {
        safeRemoveBookmarks(refMap.current);
      }
    }
  }, [mapReady, dispatch, inLocationEditMode, displayedBookmark]);

  return (
    <div ref={refMapContainer} className={styles.map}>
      <TimelineLegend
        metric={timelineMetric}
        hourlyMaxCount={hourlyMaxCount.current}
        metricsOptions={TL_METRIC_OPTIONS}
      />
      {zoomPrompt && <FloatingDiv>Zoom in to view timeline</FloatingDiv>}
      <MapSettings open={settingsOpen} handleVisSwitch={(visible) => setSettingsOpen(visible)} />
    </div>
  );
}
