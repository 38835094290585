import React from 'react';
import styles from './Spinner.module.css';

export default function LoadingSpinner(props: {
  large?: boolean
  }) {
  const { large } = props;
  return (
    <div className={large ? styles.loading_container_large : styles.loading_container}>
      <div className={large ? styles.loading_spinner_large : styles.loading_spinner} />
    </div>
  );
}

export function FloatingDiv(
  { children, round = false, clickable = false }: { round?: boolean, clickable?: boolean } & React.PropsWithChildren
) {
  const classname = round ? styles.loading_container_floating_round : styles.loading_container_floating;
  return (
    <div
      className={classname}
      style={{ pointerEvents: clickable ? 'unset' : 'none' }}
    >
      {children}
    </div>
  );
}

export function FloatingLoadingSpinner() {
  return (
    <FloatingDiv round>
      <div className={styles.loading_spinner_floating} />
    </FloatingDiv>
  );
}
