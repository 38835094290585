import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Add } from '@mui/icons-material';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { capitalize } from '@mui/material';

import { TaskBarComponent, TaskBarGroup } from 'features/task_bar/TaskBar';
import LoadingSpinner from 'features/spinner/Spinner';
import { selectProject, setCurrentScenarioId } from '../../state/workflowSlice';
import { SCENARIO_DONE, useGetScenariosQuery } from '../../state/predictSlices';
import styles from '../task_bar/Taskbar.module.css';
import { ScenarioSetupUIMode } from '../../appConstants';

function niceSegmentList(segs) {
  if (segs.length <= 1) {
    return segs[0];
  } else {
    return `${segs[0]} (+${segs.length - 1} more)`;
  }
}

function fmtTime(t) {
  return t.replace(/:00$/, '');
}

export function ScenarioList({ setTab }: { setTab: (tab) => {}}) {
  const dispatch = useDispatch();
  const project_slug = useSelector(selectProject);
  const { currentData: pastScenarios, isLoading } = useGetScenariosQuery(
    project_slug,
    { skip: !project_slug, pollingInterval: 10000 }
  );
  let listElement = <div style={{ justifyContent: 'center', display: 'flex' }}><LoadingSpinner large /></div>;
  if (!isLoading) {
    listElement = (
      <List dense className={styles.task_bar_scrolling_list} sx={{ maxHeight: '30vh !important' }}>
        { pastScenarios && pastScenarios.scenarios.map((scenario) => {
          if (scenario.status === SCENARIO_DONE) {
            return (
              <ListItemButton
                key={scenario.uuid}
                onClick={(ev) => dispatch(setCurrentScenarioId(scenario.uuid))}
              >
                <ListItemText
                  primary={niceSegmentList(scenario.segments)}
                  secondary={`${capitalize(scenario.status)} ${fmtTime(scenario.closure_start)} - ${fmtTime(scenario.closure_end)}`}
                />
              </ListItemButton>
            );
          } else {
            return (null);
          }
        })}
      </List>
    );
  }
  return (
    <TaskBarGroup>
      <Button
        onClick={(event) => {
          dispatch(setCurrentScenarioId(undefined));
          setTab(ScenarioSetupUIMode.Settings);
        }}
        startIcon={<Add />}
      >
        New Scenario
      </Button>
      <TaskBarComponent left>
        {listElement}
      </TaskBarComponent>
    </TaskBarGroup>
  );
}
