import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import {
  selectMaximize,
  selectProject, selectTargetDate, getSpecificWorkflowState, getWorkflowState, selectTypicalMonth, setTypicalMonth
} from 'state/workflowSlice';
import { useGetDatesQuery, useGetTypicalMonthsQuery } from 'state/apiSlice';

import { ChartSettingsWrapper, TaskBar, TaskBarHeader, TaskBarSkeleton } from 'features/task_bar/TaskBar';
import { workflowStyles } from 'features/workspace/workspaceUtils';
import { ScenarioSetupUIMode, WORKFLOW_SPEED, WORKFLOW_TIMELINE, WORKFLOW_TRAVEL_TIME } from 'appConstants';
import styles from 'features/workspace/Workspace.module.css';

import { NRTDatePicker } from '../task_bar/DatePicker';
import { PredictTimeSlider } from './PredictTimeSlider';
import { PredictMap } from './PredictMap';
import { PredictMetricsOption } from './PredictMetricsOption';
import { PredictScenarioStatusProvider } from './PredictScenarioStatusProvider';
import { PredictTaskBar } from './PredictTaskBar';
import { ScenarioTravelTimeChart } from './ScenarioTravelTimeChart';
import { selectScenarioSetupUIMode } from '../../state/workflowSlice';

export class workflowPredict {
  switchToState(fromWorkflow, state) {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if ([WORKFLOW_TIMELINE, WORKFLOW_SPEED, WORKFLOW_TRAVEL_TIME].includes(fromWorkflow)) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(state, fromWorkflow).targetDate;
    }
  }
}

export function WorkflowPredict() {
  const maximize = useSelector(selectMaximize);
  const project = useSelector(selectProject);

  const { map_style } = workflowStyles(maximize);

  // ======================

  const targetDate = useSelector(selectTargetDate);
  const typicalMonth = useSelector(selectTypicalMonth);
  const { data: typicalMonthsInfo } = useGetTypicalMonthsQuery(project);
  const [layer, setLayer] = useState(null);
  const dispatch = useDispatch();
  const uiMode = useSelector(selectScenarioSetupUIMode);

  // memoize so this doesn't change every render.
  const memoizedLayer = useMemo(
    () => ({ layer, setLayer }),
    [layer]
  );

  useEffect(() => {
    let new_layer = null;
    if (typicalMonthsInfo && typicalMonthsInfo.length > 0) {
      if (typicalMonth) {
        new_layer = typicalMonthsInfo.find((info) => info.typicalMonth === typicalMonth)?.layer;
      } else {
        dispatch(setTypicalMonth(typicalMonthsInfo[0].typicalMonth));
      }
    }
    // console.log(`WorkflowPlan set new layer ${new_layer} for typicalMonth ${typicalMonth}`);
    setLayer(new_layer);
  }, [typicalMonthsInfo, typicalMonth, setLayer, dispatch]);
  // ======================

  return (
    <LayerContext.Provider value={memoizedLayer}>
      <div className={styles.workspace_container}>
        <div className={styles.workspace_content}>
          <div className={map_style}>
            <PredictMap />
          </div>
          <PredictTimeSlider />
          { uiMode === ScenarioSetupUIMode.TravelTimes && <ScenarioTravelTimeChart />}
        </div>
        <TaskBarSkeleton
          upper={
          (
            <>
              <PredictTaskBar />
              <PredictScenarioStatusProvider />
            </>
          )
        }
          middle={(null)}
          lower={(
            <>
              <TaskBarHeader>Map Settings</TaskBarHeader>
              <div className={styles.task_bar_section_body}>
                <PredictMetricsOption />
              </div>
            </>
          )}
        />
      </div>
    </LayerContext.Provider>
  );
}
