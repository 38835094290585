import React, { useEffect } from 'react';
import { useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Tooltip from '@mui/material/Tooltip';

import {
  chart_background_color,
  stl_light_background_color,
  stl_light_grey,
  null_grey, workspace_color, stl_gold_color
} from 'theme/cemTheme';
import styles from './WorkflowPredict.module.css';

import {
  selectCurrentProjectInfo,
  selectTimeOfDay,
  setTimeOfDay
} from '../../state/workflowSlice';
import { ColorGradient, bg_yellow_red, grey_yellow_red } from '../chart/colorMaps';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { posMod } from '../common/utils';
import { selectScenarioEnd, selectScenarioStart } from '../../state/predictSlices';
import { formatTimeOfDay, min_string_to_minutes } from '../workflow_timeline/slotsUtils';

const timesliderMin = 0;
const timesliderMax = 24 * 60;

const timeSliderTheme = createTheme({
  components:
    {
      MuiSlider: {
        styleOverrides: {
          root: {
            disableRipple: true
          },
          thumb: {
            borderRadius: '12px',
            height: '25px',
            width: '40px',
            background: 'none',
            boxShadow: '0px 0px 15px 0px #00000080 !important',
            ':hover': {
              boxShadow: '0px 0px 15px 0px #00000080',
            },
            '::after': {
              border: '3px white solid',
              borderRadius: '12px',
              zIndex: 100,
              height: '25px',
              width: '40px',
              background: 'none',
            }
          },
          rail: {
            height: '8px',
            borderRadius: '4px',
            backgroundColor: stl_light_background_color,
            opacity: 0.8
          },
          track: {
            height: '30px',
            background: 'none',
            border: 'none',
          },
          mark: {
            height: '8px',
            borderRadius: '1px',
            backgroundColor: 'var(--primary-text)',
            opacity: 0.7,
            border: 'none'
          },
          markActive: {
            backgroundColor: stl_light_grey
          },
          markLabel: {
            color: 'var(--primary-text)'
          },
          valueLabel: {
            marginTop: '1px',
            background: `linear-gradient(to left, ${workspace_color}00, ${workspace_color}, ${workspace_color}, ${workspace_color}00)`,
            width: '20em',
            color: 'var(--primary-text)',
            transform: 'translateY(33px) !important'
          },
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            color: 'var(--primary-text)',
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            alignItems: 'start !important'
          },
          item: {
            paddingTop: '0',
          }
        }
      },
    }
});

export function PredictTimeSlider() {
  const timeOfDay = useSelector(selectTimeOfDay);
  const userProject = useSelector(selectCurrentProjectInfo);
  const closureStart = useSelector(selectScenarioStart);
  const closureEnd = useSelector(selectScenarioEnd);
  const binSize = Math.min(...userProject.available_bins);

  const dispatch = useDispatch();

  const colorMap = new ColorGradient(grey_yellow_red(0, 200).toInterleaved());
  const railBackgroundMemo = useMemo(() => {
    let railBackground = `${chart_background_color} !important`;
    if (closureStart && closureEnd) {
      const startSlots = min_string_to_minutes(closureStart);
      const endSlots = min_string_to_minutes(closureEnd);
      const startOffset = ((startSlots - timesliderMin)
        / (timesliderMax - timesliderMin))
        * 100;
      const endOffset = ((endSlots - timesliderMin)
        / (timesliderMax - timesliderMin))
        * 100;
      if (startOffset > endOffset) {
        railBackground = `linear-gradient(0.25turn, 
          ${stl_gold_color} 0%,
          ${stl_gold_color} ${(endOffset - 0.1).toFixed(2)}%, 
          ${null_grey} ${endOffset.toFixed(2)}%, 
          ${null_grey} ${startOffset.toFixed(2)}%, 
          ${stl_gold_color} ${(startOffset + 0.1).toFixed(2)}%, 
          ${stl_gold_color} 100%);`.replace('\n', '');
      } else {
        railBackground = `linear-gradient(0.25turn, 
          ${null_grey} 0%,
          ${null_grey} ${(startOffset - 0.1).toFixed(2)}%, 
          ${stl_gold_color} ${startOffset.toFixed(2)}%, 
          ${stl_gold_color} ${endOffset.toFixed(2)}%, 
          ${null_grey} ${(endOffset + 0.1).toFixed(2)}%, 
          ${null_grey} 100%);`.replace('\n', '');
      }
    }

    return railBackground;
  }, [userProject, closureStart, closureEnd]);

  useEffect(() => {
    if (closureStart && closureEnd) {
      const closureStartMins = min_string_to_minutes(closureStart);
      const closureEndMins = min_string_to_minutes(closureEnd);
      if (timeOfDay > closureEndMins || timeOfDay < closureStartMins) {
        dispatch(setTimeOfDay(Math.max(Math.min(timeOfDay, closureEndMins), closureStartMins)));
      }
    }
  }, [closureEnd, closureStart]);

  const handleSliderChange = (event: Event, newValue: number) => {
    console.assert(newValue >= timesliderMin && newValue <= timesliderMax);
    dispatch(setTimeOfDay(newValue));
    // TODO Space these so only once every 200ms
  };

  const marks = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 12 * 60,
      label: '12:00'
    },
    {
      value: 24 * 60,
      label: '00:00'
    }
  ];

  return (
    <ThemeProvider theme={timeSliderTheme}>
      <Box>
        <Grid container spacing={0} alignItems="center">
          <Grid item>
            <Tooltip title="Go back a time step">
              <IconButton
                size="small"
                onClick={(event) => dispatch(setTimeOfDay(Math.max(timeOfDay - binSize, timesliderMin)))}
                disabled={timeOfDay === timesliderMin}
                className={styles.dayChangeButton}
              >
                <ArrowLeftIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Slider
              value={timeOfDay}
              onChange={useCallbackWithErrorHandling(handleSliderChange)}
              aria-labelledby="threshold-slider"
              getAriaValueText={formatTimeOfDay}
              valueLabelFormat={formatTimeOfDay}
              valueLabelDisplay="on"
              min={timesliderMin}
              max={timesliderMax}
              step={binSize}
              color="secondary"
              marks={marks}
              sx={{
                '.MuiSlider-rail': {
                  background: railBackgroundMemo,
                }
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Go forward a time step">
              <IconButton
                size="small"
                onClick={(event) => dispatch(setTimeOfDay(Math.min(timeOfDay + binSize, timesliderMax)))}
                disabled={timeOfDay === timesliderMax}
                className={styles.dayChangeButton}
              >
                <ArrowRightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
