import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs } from '@mui/material';
import { TaskBarTabPanel } from '../task_bar/TaskBarTabs';
import { PredictSettings, PredictSettingsData } from './PredictSettings';
import { TaskBarComponent, TaskBarGroup, TaskBarHeader } from '../task_bar/TaskBar';
import { ScenarioList } from './ScenarioList';
import { ScenarioSetupUIMode } from '../../appConstants';
import { TravelTimePanel } from './TravelTimePanel';
import { selectProject, selectScenarioSetupUIMode, setScenarioSetupUIMode } from '../../state/workflowSlice';
import { useGetScenariosQuery } from '../../state/predictSlices';

export function PredictTaskBar() {
  const dispatch = useDispatch();
  const uiMode = useSelector(selectScenarioSetupUIMode);
  const project_slug = useSelector(selectProject);

  const { currentData: pastScenarios } = useGetScenariosQuery(
    project_slug,
    { skip: !project_slug }
  );

  const TABS = Object.values(ScenarioSetupUIMode);
  console.assert(TABS.includes(uiMode), uiMode, TABS);
  const setTab = (tab) => {
    if (ScenarioSetupUIMode[tab]) {
      tab = ScenarioSetupUIMode[tab];
    }
    dispatch(setScenarioSetupUIMode(tab));
  };

  useEffect(() => {
    if (TABS.indexOf(uiMode) < 0) {
      dispatch(setScenarioSetupUIMode(TABS[0]));
    }
  }, [uiMode]);

  return (
    <>
      <TaskBarGroup>
        <TaskBarHeader>Scenarios</TaskBarHeader>
      </TaskBarGroup>
      <Tabs
        value={TABS.indexOf(uiMode || ScenarioSetupUIMode.Settings)}
        // sx={{
        //   '& .MuiTabs-flexContainer': {
        //     flexWrap: 'wrap',
        //   },
        // }}
        onChange={
          (ev, index) => setTab(TABS[index])
        }
      >
        <Tab label="Setup" />
        <Tab label="Previous" />
        <Tab label="Travel Times" />
      </Tabs>
      <TaskBarTabPanel value={uiMode} index={ScenarioSetupUIMode.Settings}>
        <PredictSettings />
      </TaskBarTabPanel>
      <TaskBarTabPanel value={uiMode} index={ScenarioSetupUIMode.Scenarios}>
        <ScenarioList setTab={setTab as any} />
      </TaskBarTabPanel>
      <TaskBarTabPanel value={uiMode} index={ScenarioSetupUIMode.TravelTimes}>
        <TravelTimePanel />
      </TaskBarTabPanel>
      <PredictSettingsData />
    </>
  );
}
