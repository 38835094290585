import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';

import {
  selectMaximize,
  selectComparison, selectShortestAvailableBin
} from 'state/workflowSlice';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from 'features/chart/Chart.module.css';
import { travelTimeChartOptions } from '../chart/travelTimeChartOptions';
import { BIN_WIDTH_MINUTES_TO_MS, dayMarkerLineBase, formatTargetSeriesName, formatTypicalSeriesName } from '../chart/nrtChartCommon';
import { ScenarioTravelTimeChartDataExpander, useScenarioTravelTimeDataProvider } from './ScenarioTravelTimeProvider';
import { getBandMembership, graph_highlight_color } from '../chart/chartOptions';
import { travelTimeValueFormatter } from '../chart/TravelTimeChart';
import { getFormattedTime } from '../chart/planningChartOptions';

dayjs.extend(Duration);

export const makeFormatTooltip = (bin_size, value_formatter) => {
  function formatTooltip(this: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) {
    const ts = (this.points[1]?.x || this.points[0]?.x) as number;
    const date = getFormattedTime(ts);
    const end_time = getFormattedTime((ts as number) + (bin_size * BIN_WIDTH_MINUTES_TO_MS));
    let output = `
        <div class="chart-legend" style="font-size: 15px;">
            <h1>${date} - ${end_time}</h1>
            <table>`;
    if (this.points[1]) {
      output += `
        <tr>
            <td>
                <span style="color:${this.points[1].point.color}">●</span>${this.points[1].series.name}
            </td>
            <td>
                <b>${value_formatter(this.points[1].y)}</b>
            </td>
        </tr>`;
    }
    if (this.points[0]) {
      output += `
        <tr>
            <td>
                <span style="color:${this.points[0].point.color}">●</span>${this.points[0].series.name}
            </td>
            <td>
                <b>${value_formatter(this.points[0].y)}</b>
            </td>
        </tr>`;
    }
    return `${output}</table></div>`;
  }
  return formatTooltip;
};

export function ScenarioTravelTimeChart() {
  const comparison = useSelector(selectComparison);
  const binSize = useSelector(selectShortestAvailableBin);

  const chartComponent = useRef(null); // so we can call reflow

  const [chartOptions, setChartOptions] = useState(travelTimeChartOptions as any);
  const maximize = useSelector(selectMaximize);

  // ttData from REST api
  const { scenarioData } = useScenarioTravelTimeDataProvider();

  const dataExpander = useMemo(
    () => new ScenarioTravelTimeChartDataExpander(binSize),
    [binSize]
  );

  // memoize expanded segmentSpeeds
  const segmentTTs = useMemo(
    () => dataExpander.expandTravelTimes(scenarioData, 1),
    [scenarioData]
  );
  // console.log(`memoized segmentSpeeds ${JSON.stringify(segmentSpeeds)}`);

  // memoize typicalSpeeds
  const comparisonTTs = useMemo(
    () => {
      return dataExpander?.expandTravelTimes(scenarioData, 2);
    },
    [scenarioData]
  );
  // console.log(`###  memoized typicalSpeeds ${JSON.stringify(typicalSpeeds)}`);

  // // memoize thresholds
  // const slowdownBands = useMemo(
  //   () => dataExpander.slowdownBands(
  //     segmentTTs,
  //     comparisonTTs,
  //     1 + slowdownThreshold,
  //     true
  //   ),
  //   [segmentTTs, comparisonTTs, slowdownThreshold]
  // );

  // update chart with new targetDate, freeflowSpeeds, segmentSpeeds
  useEffect(() => {
    setChartOptions({
      time: {
        useUTC: true,
      },
      // xAxis: {
      // },
      series: [
        {
          id: 'typical_tt',
          name: 'Comparison traveltime',
          data: comparisonTTs || [],
          marker: { enabled: false },
        },
        {
          id: 'segment_tt',
          data: segmentTTs || [],
          name: 'Scenario traveltime',
          marker: { enabled: false },
        },
      ],
      tooltip: {
        formatter: makeFormatTooltip(
          scenarioData?.bin_size,
          travelTimeValueFormatter
        )
      }
    });
  }, [segmentTTs, comparisonTTs]);

  useEffect(() => {
    const chart = chartComponent.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [maximize]);

  return (
    <div className={styles.chart}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
}
