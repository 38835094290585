import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectComparison, selectCurrentScenarioId,
  selectProject,
  selectRouteSelection,
  selectSegmentId, selectTargetDate,
} from '../../state/workflowSlice';
import { LayerContext } from '../../state/LayerContext';
import {
  useGetTypicalRouteTravelTimeQuery, useGetRouteTravelTimeQuery, useGetLayerQuery
} from '../../state/apiSlice';
import { getRouteForSparseSegments } from '../map/mapUtils';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';
import { selectScenarioDayType, useGetScenarioTravelTimeQuery } from '../../state/predictSlices';
import { ChartDataExpander, sparseToDense } from '../chart/ChartDataExpander';
import { TravelTimeRouteData, XyArray } from '../../appTypes';
import { bin_size_to_minutes, MINUTES_PER_DAY, PlanningChartDataExpander } from '../chart/PlanningChartDataExpander';

export function useScenarioTravelTimeDataProvider() {
  const project_slug = useSelector(selectProject);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const comparison = useSelector(selectComparison);
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const scenarioDayType = useSelector(selectScenarioDayType);

  const { layer } = useContext(LayerContext);

  // Get data for Route volumes
  const { currentData: layerData, } = useGetLayerQuery(layer, { skip: !layer });

  const isMonthComparison = comparison && isComparisonMonth(comparison);

  let routeSegments;
  if (routeSelection && layerData?.routing) {
    routeSegments = getRouteForSparseSegments(layerData.routing, routeSelection);
  } else if (segmentId) {
    routeSegments = [segmentId];
  }

  const { currentData: scenarioData } = useGetScenarioTravelTimeQuery(
    { project_slug, uuid: currentScenarioId, routeIds: routeSegments },
    { skip: !(routeSegments) }
  ) as any;

  return {
    scenarioData
  };
}

export class ScenarioTravelTimeChartDataExpander extends PlanningChartDataExpander {
  constructor(binSize: string | number) {
    super(binSize);
    this.numBins = (MINUTES_PER_DAY) / this.binSize + 1;
    // console.log(`numBins ${this.numBins} firstBin ${this.firstBin} lastBin ${this.lastBin}`);
  }

  toIndex(bin: string) {
    const bin_split = bin.split(':');
    const bin_h = Number.parseInt(bin_split[0], 10);
    const bin_m = Number.parseInt(bin_split[1], 10);
    const fix_bin = bin_h * 60 + bin_m;
    return fix_bin / this.binSize;
  }

  expandTravelTimes(rawData: TravelTimeRouteData, data_offset): XyArray {
    // extract and backfill sparse SpeedData array with null datapoints for all time bins

    const binTicks = this.binSize * 60 * 1000;

    const data = Array(this.numBins);
    for (let i = 0; i < this.numBins; i += 1) {
      const b = this.firstBin + (i * binTicks);
      data[i] = { x: b, y: null };
    }
    // console.log(`expandSpeeds empty speeds: ${JSON.stringify(speeds.slice(0, 10))}`);
    function traveltime_data_map(row) {
      return { bin: row[0], y: row[data_offset] };
    }
    if (rawData) {
      const sparseData = rawData.times.map(traveltime_data_map);
      for (let i = 0; i < sparseData.length; i += 1) {
        const { bin, y } = sparseData[i];
        const index = this.toIndex(bin);
        // console.assert(index % 1 === 0);
        // console.log(`index: ${index},  bin: ${bin}, y: ${y}`);
        if (y) {
          data[index].y = y * (60.0 / this.binSize);
        }
      }
    }
    // console.log(`expandPlanningData data: ${JSON.stringify(data.slice(0, 10))}`);

    return data;
  }
}
